import { defineStore } from 'pinia'
import { type Ref, readonly, ref, watch } from 'vue'

import { defaultProductNavigation } from '@/data'
import { getCurrentLocale } from '@/locale'
import CookieService from '@/utils/CookieService'
import {
  setQueryStringDeviceColor,
  setQueryStringDeviceHandle,
  setQueryStringProductColor,
  setQueryStringProductHandle,
  setQueryStringProductNavigation,
} from '@/utils/queryString'

const cookieClient = new CookieService(getCurrentLocale())

export interface StateConfig {
  device: null | string
  deviceColor: null | string
  product: null | string
  productColor: null | string
  productNavigation: string
}

export const useStateStore = defineStore('state', () => {
  const selectedProductNavigation = ref<string>(defaultProductNavigation)
  const selectedDeviceHandle: Ref<null | string> = ref(null)
  const selectedDeviceColor: Ref<null | string> = ref(null)
  const selectedProduct: Ref<null | string> = ref(null)
  const selectedProductColor: Ref<null | string> = ref(null)

  watch(selectedProductNavigation, async (productNavigation) => {
    cookieClient.setProductNavigation(productNavigation)
    await setQueryStringProductNavigation(productNavigation)
  })

  watch(selectedDeviceHandle, async (deviceHandle) => {
    cookieClient.setDeviceHandle(selectedProductNavigation.value, deviceHandle ?? '')
    await setQueryStringDeviceHandle(deviceHandle ?? '')
  })

  watch(selectedDeviceColor, async (deviceColor) => {
    cookieClient.setDeviceColor(selectedProductNavigation.value, deviceColor ?? '')
    await setQueryStringDeviceColor(deviceColor ?? '')
  })

  watch(selectedProduct, async (productHandle) => {
    cookieClient.setProductHandle(selectedProductNavigation.value, productHandle ?? '')
    await setQueryStringProductHandle(productHandle ?? '')
  })

  watch(selectedProductColor, async (productColor) => {
    cookieClient.setProductColor(selectedProductNavigation.value, productColor ?? '')
    await setQueryStringProductColor(productColor ?? '')
  })

  function updateProductNavigation(productNavigation: string): void {
    selectedProductNavigation.value = productNavigation
  }

  function updateDeviceHandle(deviceHandle: null | string): void {
    selectedDeviceHandle.value = deviceHandle
  }

  function updateDeviceColor(deviceColor: null | string): void {
    selectedDeviceColor.value = deviceColor
  }

  function updateProduct(product: null | string): void {
    selectedProduct.value = product
  }

  function updateProductColor(productColor: null | string): void {
    selectedProductColor.value = productColor
  }

  function exportConfig(): StateConfig {
    return {
      productNavigation: selectedProductNavigation.value,
      product: selectedProduct.value,
      productColor: selectedProductColor.value,
      device: selectedDeviceHandle.value,
      deviceColor: selectedDeviceColor.value,
    }
  }

  return {
    selectedProductNavigation: readonly(selectedProductNavigation),
    selectedDeviceHandle: readonly(selectedDeviceHandle),
    selectedDeviceColor: readonly(selectedDeviceColor),
    selectedProduct: readonly(selectedProduct),
    selectedProductColor: readonly(selectedProductColor),
    updateProductNavigation,
    updateDeviceHandle,
    updateDeviceColor,
    updateProduct,
    updateProductColor,
    exportConfig,
  }
})
