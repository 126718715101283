export function isObject(x: unknown): x is Record<string, unknown> {
  return typeof x === 'object' && x !== null
}

export function isArrayOf<T>(x: unknown, isT: (x: unknown) => boolean): x is T[] {
  return Array.isArray(x) && x.every((x) => isT(x))
}

export interface CoordinatedEvent extends Event {
  clientX: number
  clientY: number
}
export function isCoordinatedEvent(e: Event | Touch): e is CoordinatedEvent {
  return 'clientX' in e && 'clientY' in e
}

// 在 '@/data' 裡面有重複，但因為 product 會拆掉，先不從那邊 import
export type ArrayElement<T> = T extends Array<infer P> ? P : never

export interface Rect {
  readonly height: number
  readonly width: number
  readonly x: number
  readonly y: number
}

export interface PositionedImage {
  readonly rect: Rect
  readonly url: string
}
