export enum Route {
  ArtistPage = '/artist/:id?',
  ArtistsPage = '/artists',
  BackgroundPage = '/background/:id?',
  CheckoutPage = '/checkout',
  CollagePage = '/collage/:id?',
  DesignArtistPage = '/design/:idOrHandle',
  DesignPage = '/design',
  FavoriteArtworkPage = '/favorite-artwork',
  GalleryPage = '/gallery',
  HomePage = '/home',
  Root = '/',
  StickerGroupPage = '/sticker-group/:id?',
}
