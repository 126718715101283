import { type InitOptions } from 'i18next'
import * as i18next from 'i18next'

import { Locale, getCurrentIsProduction, getCurrentLanguage } from '@/locale'
import de from '@/locales/de.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import fr from '@/locales/fr.json'
import jp from '@/locales/jp.json'
import kr from '@/locales/kr.json'
import th from '@/locales/th.json'
import tw from '@/locales/tw.json'

// 預設的語言
const lng: InitOptions['lng'] = getCurrentLanguage()

const translations: Record<Locale, object> = {
  [Locale.TW]: tw,
  [Locale.JP]: jp,
  [Locale.TH]: th,
  [Locale.ASIA]: en,
  [Locale.IO]: en,
  [Locale.EU]: en,
  [Locale.UK]: en,
  [Locale.FR]: fr,
  [Locale.DE]: de,
  [Locale.ES]: es,
  [Locale.KR]: kr,
}

const resources: InitOptions['resources'] = Object.fromEntries(
  Object.entries(translations)
    .map(([key, data]) => ({ key, translation: data }))
    .map(({ key, translation }) => [key, { translation }]),
)

i18next.use({
  type: 'postProcessor',
  name: 'aTagTargetBlank',
  process: function (value: string): string {
    return value.replaceAll('<a ', '<a target="_blank" ')
  },
})

void i18next.init({
  lng,
  resources,
  fallbackLng: Locale.IO,
  debug: !getCurrentIsProduction(),
})

export { default } from 'i18next'
