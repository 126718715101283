import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import { updateUseRouter } from '@/utils/queryString'

import { Route } from './route'

const routes: RouteRecordRaw[] = [
  {
    path: Route.Root,
    name: Route.Root,
    redirect(to) {
      return { path: Route.GalleryPage, query: to.query }
    },
  },
  {
    path: Route.HomePage,
    name: Route.HomePage,
    component: async () => await import('@/pages/HomePage.vue'),
  },
  {
    path: Route.DesignPage,
    name: Route.DesignPage,
    component: async () => await import('@/pages/DesignPage.vue'),
  },
  {
    path: Route.DesignArtistPage,
    name: Route.DesignArtistPage,
    component: async () => await import('@/pages/DesignArtistPage.vue'),
  },
  {
    path: Route.CheckoutPage,
    name: Route.CheckoutPage,
    component: async () => await import('@/pages/CheckoutPage.vue'),
  },
  {
    path: Route.ArtistPage,
    name: Route.ArtistPage,
    component: async () => await import('@/pages/ArtistPage.vue'),
  },
  {
    path: Route.GalleryPage,
    name: Route.GalleryPage,
    component: async () => await import('@/pages/GalleryPage.vue'),
  },
  {
    path: Route.StickerGroupPage,
    name: Route.StickerGroupPage,
    component: async () => await import('@/pages/productPage/StickerGroupPage.vue'),
  },
  {
    path: Route.BackgroundPage,
    name: Route.BackgroundPage,
    component: async () => await import('@/pages/productPage/BackgroundPage.vue'),
  },
  {
    path: Route.CollagePage,
    name: Route.CollagePage,
    component: async () => await import('@/pages/productPage/CollagePage.vue'),
  },
  {
    path: Route.ArtistsPage,
    name: Route.ArtistsPage,
    component: async () => await import('@/pages/ArtistsPage.vue'),
  },
  {
    path: Route.FavoriteArtworkPage,
    name: Route.FavoriteArtworkPage,
    component: async () => await import('@/pages/FavoriteArtworkPage.vue'),
  },
]

export const router = createRouter({ routes, history: createWebHistory() })
updateUseRouter(router)

function isEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0
}

router.beforeEach((to, from, next) => {
  const usePreviousQueryParams = isEmpty(to.query) && !isEmpty(from.query)
  if (usePreviousQueryParams) {
    next({ ...to, query: from.query })
  } else {
    next()
  }
})
