import { setCachedDataUrl } from '@evolutivelabs/amuse-design-canvas'
import { defineStore } from 'pinia'
import { type Ref, computed, readonly, ref, watch } from 'vue'

import { getProductsForDevice } from '@/data'
import {
  type DesignGroup,
  type Product,
  type ProductList,
  type ProductNavigation,
  type ProductVariant,
} from '@/data/types'
import { priceWithLocaleCurrencyUnit } from '@/locale'
import { getProductPendingSkus } from '@/utils/productStatus'

import { useStateStore } from './stateStore'

export const useProductStore = defineStore('product', () => {
  const stateStore = useStateStore()
  const productsData: Ref<ProductList | null> = ref(null)

  const selectedProduct = computed(() => stateStore.selectedProduct)
  async function updateProductsForDevice(deviceHandle: string): Promise<void> {
    productsData.value = await getProductsForDevice(deviceHandle)
  }
  async function updateProductsForProductNavigation(
    productNavigation: string,
    deviceHandle: null | string,
    productNavigationMap: Ref<ProductNavigation>,
  ): Promise<void> {
    const currentProductNavigation = productNavigationMap.value.get(productNavigation)
    if (currentProductNavigation === undefined) {
      return
    }
    productsData.value = await currentProductNavigation.getProducts(deviceHandle)
  }
  function updateProduct(productHandle: string): void {
    if (selectableProduct.value.includes(productHandle)) {
      stateStore.updateProduct(productHandle)
    }
  }
  const productSeries = computed((): null | string => {
    // 型別推的怪怪的，不知道為啥下面的 find has 還是會帶入 null，所以只好先取出來使用
    const productHandle = selectedProduct.value
    if (productsData.value === null || productHandle === null) {
      return null
    }
    const findProductSeries = [...productsData.value.entries()].find(([, item]) =>
      item.productVariants.has(productHandle),
    )
    return findProductSeries?.[0] ?? null
  })
  const productSeriesItem = computed((): Product | null => {
    if (productsData.value === null || productSeries.value === null) {
      return null
    }
    return productsData.value.get(productSeries.value) ?? null
  })
  const productVariantItem = computed((): ProductVariant | null => {
    if (productSeriesItem.value === null || selectedProduct.value === null) {
      return null
    }
    return productSeriesItem.value.productVariants.get(selectedProduct.value) ?? null
  })
  const selectableProduct = computed((): string[] => {
    if (productsData.value === null) {
      return []
    }
    return [...productsData.value.values()].flatMap(({ productVariants }) => [
      ...productVariants.keys(),
    ])
  })
  watch(
    selectableProduct,
    () => {
      if (
        selectedProduct.value !== null &&
        selectableProduct.value.includes(selectedProduct.value)
      ) {
        return
      }
      const firstProduct = selectableProduct.value[0]
      if (firstProduct === undefined) {
        stateStore.updateProduct(null)
      } else {
        updateProduct(firstProduct)
      }
    },
    { deep: true },
  )
  const selectedProductTitle = computed((): { title: string; versionTitle: string } | null => {
    if (productSeriesItem.value === null || productVariantItem.value === null) {
      return null
    }

    const title = `${productSeriesItem.value.title}${
      productVariantItem.value.groupTitle === null ? '' : ` ${productVariantItem.value.groupTitle}`
    }`

    return { title, versionTitle: productVariantItem.value.versionTitle }
  })
  const selectedProductFullTitle = computed(() => {
    if (selectedProductTitle.value === null) {
      return ''
    }

    const { title, versionTitle } = selectedProductTitle.value
    return `${title} (${versionTitle})`
  })

  const selectedProductColor = computed(() => stateStore.selectedProductColor)
  const selectableProductColor = computed((): string[] => {
    if (productVariantItem.value === null) {
      return []
    }

    return [...productVariantItem.value.colorForVariants.keys()]
  })
  function updateProductColor(productColor: string): void {
    if (selectableProductColor.value.includes(productColor)) {
      stateStore.updateProductColor(productColor)
    }
  }
  watch(
    selectableProductColor,
    () => {
      if (
        selectedProductColor.value !== null &&
        selectableProductColor.value.includes(selectedProductColor.value)
      ) {
        return
      }
      const firstDeviceColor = selectableProductColor.value[0]
      if (firstDeviceColor === undefined) {
        stateStore.updateProductColor(null)
      } else {
        updateProductColor(firstDeviceColor)
      }
    },
    { deep: true },
  )
  const productColorVariantItem = computed(() => {
    if (selectedProductColor.value === null) {
      return null
    }
    return productVariantItem.value?.colorForVariants.get(selectedProductColor.value) ?? null
  })

  const maskImage = computed(() => {
    return productVariantItem.value?.previewMask ?? null
  })
  const preDesignImages = computed((): string[] => {
    if (productVariantItem.value === null || productColorVariantItem.value === null) {
      return []
    }
    const { images } = productColorVariantItem.value
    const imageUrls = productVariantItem.value.preDesignLayers
      .map((layer) => images.get(layer) ?? null)
      .filter((url): url is string => typeof url === 'string')
    for (const url of imageUrls) {
      void setCachedDataUrl(url)
    }
    return imageUrls
  })
  const postDesignImages = computed((): string[] => {
    if (productVariantItem.value === null || productColorVariantItem.value === null) {
      return []
    }
    const { images } = productColorVariantItem.value
    const imageUrls: string[] = productVariantItem.value.postDesignLayers
      .map((layer) => images.get(layer) ?? null)
      .filter((url): url is string => typeof url === 'string')
    for (const url of imageUrls) {
      void setCachedDataUrl(url)
    }
    return imageUrls
  })
  const designDeconstructionHandle = computed(
    () => productVariantItem.value?.designDeconstructionHandle ?? null,
  )
  const printInfo = computed(() => productVariantItem.value?.printInfo ?? null)
  const thumbnailImage = computed(() => productSeriesItem.value?.thumbnailImage ?? null)
  const printFileNeedToMirror = computed(() => printInfo.value?.needToMirror ?? null)
  const checkoutVariants = computed(() => productColorVariantItem.value?.variants ?? null)
  const price = computed(() => productColorVariantItem.value?.price ?? 0)
  const compareAtPrice = computed(
    () => productColorVariantItem.value?.compareAtPrice ?? price.value,
  )
  const priceWithCurrencyUnit = computed(() => priceWithLocaleCurrencyUnit(price.value))
  const viewableRect = computed(() => productVariantItem.value?.viewableRect ?? null)
  const bindingDesignGroup = computed(
    (): DesignGroup | null => productSeriesItem.value?.bindingDesignGroup ?? null,
  )

  const productPendingSkus = ref(new Set<string>())
  const productIsPending = computed(() => {
    const skuList = (checkoutVariants.value?.map((item) => item.sku) ?? []).filter(
      (item): item is string => typeof item === 'string',
    )
    return skuList.some((sku) => productPendingSkus.value.has(sku))
  })
  async function initProductPendingSkus(): Promise<void> {
    const productStatus = await getProductPendingSkus()
    if (productStatus !== null) {
      productPendingSkus.value = productStatus
    }
  }
  const overwriteProductDesignSize = computed(
    () => productSeriesItem.value?.overwriteDesignSize ?? null,
  )

  return {
    selectedProduct: readonly(selectedProduct),
    selectedProductTitle,
    selectedProductFullTitle,
    productsData: readonly(productsData),
    selectableProduct,
    updateProductsForDevice,
    updateProductsForProductNavigation,
    updateProduct,
    selectedProductColor: readonly(selectedProductColor),
    selectableProductColor,
    updateProductColor,
    maskImage,
    viewableRect,
    preDesignImages,
    postDesignImages,
    printInfo,
    thumbnailImage,
    printFileNeedToMirror,
    checkoutVariants,
    price,
    compareAtPrice,
    priceWithCurrencyUnit,
    productIsPending,
    initProductPendingSkus,
    bindingDesignGroup,
    overwriteProductDesignSize,
    designDeconstructionHandle,
  }
})
