import { setCachedDataUrl } from '@evolutivelabs/amuse-design-canvas'
import { defineStore } from 'pinia'
import { type Ref, computed, readonly, ref, watch } from 'vue'

import { getDevices } from '@/data'
import { type DeviceItem, type Devices } from '@/data/types'
import { type DeviceType } from '@/graphql/generated_villus'

import { useProductStore } from './productStore'
import { useStateStore } from './stateStore'

export const useDeviceStore = defineStore('device', () => {
  const productStore = useProductStore()
  const stateStore = useStateStore()
  const devices: Ref<Devices | null> = ref(null)

  const selectedDeviceHandle = computed((): null | string => stateStore.selectedDeviceHandle)

  const selectableDevice = computed((): string[] => {
    if (devices.value === null) {
      return []
    }
    return [...devices.value.values()]
      .flatMap(({ devices }) => [...devices])
      .map(([deviceHandle]) => deviceHandle)
  })
  const selectedDeviceItem = computed((): DeviceItem | null => {
    if (devices.value === null) {
      return null
    }
    const deviceDataList = [...devices.value.values()]
      .flatMap(({ devices }) => [...devices])
      .map(([deviceHandle, deviceItem]) => ({ deviceHandle, deviceItem }))
    const findDeviceItem = deviceDataList.find(
      (deviceItem) => deviceItem.deviceHandle === selectedDeviceHandle.value,
    )?.deviceItem
    return findDeviceItem ?? null
  })
  const selectedDeviceTitle = computed((): null | string => {
    const deviceItem = selectedDeviceItem.value
    return deviceItem?.title ?? null
  })
  async function updateDevice(deviceHandle: null | string): Promise<void> {
    if (deviceHandle !== null && selectableDevice.value.includes(deviceHandle)) {
      stateStore.updateDeviceHandle(deviceHandle)
      await productStore.updateProductsForDevice(deviceHandle)
    } else {
      const firstDevice = selectableDevice.value[0]
      stateStore.updateDeviceHandle(firstDevice ?? null)
    }
  }

  const selectedBrandTitle = computed(() => {
    if (devices.value === null || selectedDeviceHandle.value === null) {
      return null
    }
    return (
      [...devices.value.values()].find(
        (deviceList) =>
          selectedDeviceHandle.value !== null && deviceList.devices.has(selectedDeviceHandle.value),
      )?.title ?? null
    )
  })

  const selectedDeviceColor = computed(() => stateStore.selectedDeviceColor)
  const selectableDeviceColor = computed((): string[] => {
    const deviceItem = selectedDeviceItem.value
    return deviceItem === null ? [] : [...deviceItem.colorForImages.keys()]
  })
  const selectedDeviceImage = computed((): null | string => {
    if (selectedDeviceColor.value === null) {
      return null
    }
    const deviceItem = selectedDeviceItem.value
    const deviceImageUrl = deviceItem?.colorForImages.get(selectedDeviceColor.value) ?? null
    if (deviceImageUrl !== null) {
      void setCachedDataUrl(deviceImageUrl)
    }
    return deviceImageUrl
  })
  function updateDeviceColor(deviceColor: string): void {
    if (selectableDeviceColor.value.includes(deviceColor)) {
      stateStore.updateDeviceColor(deviceColor)
    }
  }
  watch(
    selectableDeviceColor,
    () => {
      if (
        selectedDeviceColor.value !== null &&
        selectableDeviceColor.value.includes(selectedDeviceColor.value)
      ) {
        return
      }
      const firstDeviceColor = selectableDeviceColor.value[0]
      if (firstDeviceColor === undefined) {
        stateStore.updateDeviceColor(null)
      } else {
        updateDeviceColor(firstDeviceColor)
      }
    },
    { deep: true },
  )

  async function updateDeviceData(deviceType: DeviceType | null): Promise<void> {
    devices.value = await getDevices(deviceType)
  }

  watch(
    selectableDevice,
    async () => {
      if (
        selectedDeviceHandle.value === null ||
        !selectableDevice.value.includes(selectedDeviceHandle.value)
      ) {
        const firstDevice = selectableDevice.value[0]
        await updateDevice(firstDevice ?? null)
      }
    },
    { deep: true },
  )

  return {
    devices: readonly(devices),
    selectedDeviceHandle: readonly(selectedDeviceHandle),
    selectedBrandTitle: readonly(selectedBrandTitle),
    selectableDevice,
    selectedDeviceTitle,
    updateDevice,
    selectedDeviceColor: readonly(selectedDeviceColor),
    selectableDeviceColor,
    selectedDeviceImage,
    updateDeviceColor,
    updateDeviceData,
  }
})
