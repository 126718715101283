import { Locale, getCurrentLocale } from '@/locale'

export enum Font {
  AntonRegular = 'Anton-Regular',
  BebasNeueRegular = 'BebasNeue-Regular',
  BungeeRegular = 'Bungee-Regular',
  CoinyRegular = 'Coiny-Regular',
  CwTeXMingZhonly = '明體',
  HoltwoodOneSC = 'HoltwoodOneSC',
  LibreBaskervilleBold = 'LibreBaskerville-Bold',
  MerriweatherBold = 'Merriweather-Bold',
  MerriweatherBoldItalic = 'Merriweather-BoldItalic',
  NotoSansCJKtcBlack = 'NotoSansCJKtc-Black', // '黑體'
  NotoSansCJKtcBlackInZh = '黑體',
  NotoSansJPBold = 'NotoSans JP 日本語対応フォント',
  NotoSansThaiBold = 'NotoSans-Thai',
  OswaldSemiBold = 'Oswald-SemiBold',
  PermanentMarkerRegular = 'PermanentMarker-Regular',
  PressStart2PRegular = 'PressStart2P-Regular',
  QuanticoBold = 'Quantico-Bold',
  QuanticoBoldItalic = 'Quantico-BoldItalic',
  RobotoBold = 'Roboto-Bold',
  RobotoBoldItalic = 'Roboto-BoldItalic',
  SarinaRegular = 'Sarina-Regular',
}

export const fonts: Record<Font, string> = {
  [Font.NotoSansCJKtcBlackInZh]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/NotoSansCJKtc-Black.ttf?8944033650602727086',
  [Font.CwTeXMingZhonly]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/cwTeXMing-zhonly.ttf?4706141921037546365',
  [Font.RobotoBold]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Roboto-Bold.ttf?6715089704466941401',
  [Font.RobotoBoldItalic]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Roboto-BoldItalic.ttf?6715089704466941401',
  [Font.AntonRegular]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Anton-Regular.ttf?6715089704466941401',
  [Font.OswaldSemiBold]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Oswald-SemiBold.ttf?6715089704466941401',
  [Font.BungeeRegular]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Bungee-Regular.ttf?6715089704466941401',
  [Font.CoinyRegular]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Coiny-Regular.ttf?6715089704466941401',
  [Font.QuanticoBold]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Quantico-Bold.ttf?6715089704466941401',
  [Font.QuanticoBoldItalic]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Quantico-BoldItalic.ttf?6715089704466941401',
  [Font.MerriweatherBold]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Merriweather-Bold.ttf?6715089704466941401',
  [Font.MerriweatherBoldItalic]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Merriweather-BoldItalic.ttf?6715089704466941401',
  [Font.LibreBaskervilleBold]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/LibreBaskerville-Bold.ttf?6715089704466941401',
  [Font.HoltwoodOneSC]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/HoltwoodOneSC.ttf?6715089704466941401',
  [Font.SarinaRegular]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/Sarina-Regular.ttf?6715089704466941401',
  [Font.PermanentMarkerRegular]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/PermanentMarker-Regular.ttf?6715089704466941401',
  [Font.PressStart2PRegular]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/PressStart2P-Regular.ttf?6715089704466941401',
  [Font.NotoSansCJKtcBlack]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/NotoSansCJKtc-Black.ttf?8944033650602727086',
  [Font.BebasNeueRegular]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/BebasNeue-Regular.ttf?v=1627960399',
  [Font.NotoSansJPBold]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/NotoSansJP-Bold.otf?v=1679586232',
  [Font.NotoSansThaiBold]:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/NotoSansThai-Bold.ttf?v=1679586135',
}

export function isDesignFont(fontFamily: string): fontFamily is Font {
  const fonts: string[] = Object.values(Font)
  return fonts.includes(fontFamily)
}

export const fallbackFont =
  getCurrentLocale() === Locale.TH ? Font.NotoSansThaiBold : Font.NotoSansCJKtcBlack
