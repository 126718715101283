<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="visible"
        class="fixed inset-0 z-300 overflow-hidden"
        :class="{
          'bg-transparent-70': !notShowBlackCover,
        }"
        @click="closeBackdrop($event)"
      >
        <div
          class="h-100vh flex items-center justify-center"
          :style="{ 'max-height': '-webkit-fill-available' }"
        >
          <transition
            enter-active-class="transition ease-out duration-300 transform"
            enter-from-class="opacity-0 translate-y-10 scale-60"
            enter-to-class="opacity-100 translate-y-0 scale-100"
          >
            <!--        modal -->
            <div
              v-if="visible"
              ref="modal"
              class="relative m-10px max-h-80vh max-w-480px overflow-hidden rounded-5px bg-white text-center shadow-modal"
              :style="{ width: dialogWidth }"
            >
              <!-- eslint-enable -->
              <div class="h-40px flex justify-end p-8px">
                <i
                  v-if="!hideCloseButton"
                  class="icon-close cursor-pointer text-24px"
                  @click="
                    () => {
                      pushGA4ButtonClick(
                        `modal dialog close${componentName !== '' ? `: ${componentName}` : ''}`,
                      )
                      closeModal()
                    }
                  "
                />
              </div>
              <div
                class="overflow-y-overlay px-20px scrollbar"
                :style="{
                  'max-height': `calc(80vh - ${otherElementHeight})`,
                }"
              >
                <div
                  v-if="$slots['header']"
                  class="flex items-center justify-center title-14-h20-b text-primary-100"
                >
                  <slot name="header" />
                </div>
                <div v-if="$slots['content']" class="mt-8px content-16-h24-r text-primary-100">
                  <slot name="content" />
                </div>
              </div>
              <div
                v-if="$slots['footer']"
                ref="footerElement"
                class="modal-footer flex justify-center pb-24px pt-16px content-12-h16-r"
              >
                <slot name="footer" />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'

import { pushGA4ButtonClick } from '@/utils/googleAnalytics'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dismissibleMask: {
      type: Boolean,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: '',
    },
    notShowBlackCover: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    /**
     * 目前主要是設定給 GA 紀錄資料用的
     */
    componentName: {
      type: String,
      default: '',
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'update:visible': (_visible: boolean) => true,
    hide: () => true,
  },
  setup(props, { emit }) {
    watch(
      () => props.visible,
      () => {
        if (props.visible) document.querySelector('body')?.classList.add('overflow-hidden')
        else {
          emit('hide')
          document.querySelector('body')?.classList.remove('overflow-hidden')
        }
      },
    )

    const modal = ref<HTMLElement | null>(null)

    function closeModal(): void {
      emit('update:visible', false)
    }

    function closeBackdrop(e: Event): void {
      if (!props.dismissibleMask || modal.value == null || e.composedPath().includes(modal.value)) {
        return
      }
      closeModal()
    }

    const footerElement = ref<HTMLElement | null>(null)
    const otherElementHeight = computed(() => {
      const footerHeight = footerElement.value?.scrollHeight ?? 0
      const otherHeight = 40 // close 40px
      return `${footerHeight + otherHeight}px`
    })

    return {
      closeBackdrop,
      closeModal,
      modal,
      footerElement,
      otherElementHeight,
    }
  },
  methods: { pushGA4ButtonClick },
  expose: [],
})
</script>

<style scoped>
.modal-footer :deep(button) {
  @apply min-h-36px min-w-100px px-12px py-10px flex items-center justify-center;
}

.modal-footer :deep(button:first-child) {
  @apply ml-32px;
}

.modal-footer :deep(button:last-child) {
  @apply mr-32px;
}

.modal-footer :deep(button:not(:first-child)) {
  @apply ml-36px;
}
</style>
