export enum LocalStorage {
  /**
   * 藝術家版本的 Design Page 用
   */
  ArtistIdForCustomization = 'artist_id_for_customization',
  /**
   * 測試環境為了結帳用的
   */
  Checkout = 'checkout',
  /**
   * 使用者的設計資料
   */
  DesignBindDesignGroupType = 'design_bind_design_group_type',
  /**
   * 使用者的設計資料
   */
  DesignConfig = 'design_config',
  /**
   * 最愛的作品
   */
  FavoriteArtwork = 'favorite_artwork',
  /**
   * 報表分析用的, 創 ccid 時會傳給後端
   */
  LastCollageId = 'last_collage_id',
  /**
   * 上傳的圖檔
   */
  Photo = 'photo',
  /**
   * design page 背景最近使用
   */
  RecentBackground = 'recent_background',
  /**
   * design page 設計最近使用
   */
  RecentCollage = 'recent_collage',
  /**
   * design page 貼圖最近使用
   */
  RecentStickerGroup = 'recent_sticker_group',
  /**
   * 讓 staging 跟 local 可以連上 prod 測試正式資料用的
   */
  ViteGraphqlEndpointURL = 'VITE_GRAPHQL_ENDPOINT_URL',
}

export enum SessionStorage {
  /**
   * 改為 design page 提醒閃爍
   */
  FAQModalNotShow = 'faq_modal_not_show',
}
