import '@unocss/reset/tailwind.css'
import '@evolutivelabs/amuse-design-canvas/style.css'
import '@evolutivelabs/amuse-ui/style.css'
import 'reflect-metadata'
import 'virtual:uno.css'

import { plugin as auDesignCanvas } from '@evolutivelabs/amuse-design-canvas'
import * as Sentry from '@sentry/vue'
import i18nextVue from 'i18next-vue'
import { createPinia } from 'pinia'
import { type DirectiveBinding, createApp } from 'vue'

import i18next from '@/i18nConfig'
import { HostEnv, getCurrentIsProduction, getNodeENV } from '@/locale'
import { fallbackFont, fonts } from '@/materials/fonts.config'
import { router } from '@/router'
import { cleanTranslation } from '@/utils/common'
import shopifyClient from '@/utils/ShopifyService'
import { LocalStorage } from '@/utils/storage'
import { villusClient } from '@/utils/villus'

import App from './App.vue'
import { useProductStore } from './store/productStore'

const pinia = createPinia()

const app = createApp(App)
app.use(i18nextVue, { i18next })
app.use(villusClient)
app.use(router)
app.use(pinia)
app.use(auDesignCanvas, {
  fontUtil: {
    fallbackFont,
    minFontSize: 12,
    fontSourceMap: new Map<string, string>(Object.entries(fonts)),
  },
  designStorage: {
    load: () => localStorage.getItem(LocalStorage.DesignConfig),
    save: (value: string) => {
      localStorage.setItem(LocalStorage.DesignConfig, value)
      const productStore = useProductStore()
      localStorage.setItem(
        LocalStorage.DesignBindDesignGroupType,
        productStore.bindingDesignGroup?.type ?? '',
      )
    },
  },
})

app.directive('clean-html', (el: Element, binding: DirectiveBinding<string>) => {
  el.innerHTML = cleanTranslation(binding.value)
})

interface HTMLElementWithClickOutside extends HTMLElement {
  clickOutsideEvent: (event: Event) => void
}
app.directive('click-outside', {
  beforeMount(el: HTMLElementWithClickOutside, binding: DirectiveBinding<() => void>) {
    el.clickOutsideEvent = function (event) {
      if (!(event.target instanceof Node)) return
      if (!el.contains(event.target)) {
        binding.value()
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el: HTMLElementWithClickOutside) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
})

function getSentrySampleRate(): number {
  try {
    // 正常狀況下應該這邊可以抓到 user agent 但我怕有特殊案例這邊會抓不到，保險起見還是包一層 try catch 比較好
    if (navigator.userAgent.includes('SamsungBrowser')) {
      return 1
    }
  } catch {
    // do nothing
  }
  return getCurrentIsProduction() ? 0.1 : 1
}
if ([HostEnv.Production, HostEnv.Staging].includes(getNodeENV())) {
  Sentry.init({
    app,
    dsn: 'https://2485ff49b6ef4f3f84a1e02e837cc3bd@o4505356853575680.ingest.sentry.io/4505361149198336',
    environment: getNodeENV(),
    integrations: [
      // Docs: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/vue-router/
      Sentry.browserTracingIntegration({ router }),

      // Docs: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/plugin/#httpclient
      // eslint-disable-next-line import-x/namespace
      Sentry.httpClientIntegration(),
    ],
    // Docs: https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/
    tracesSampleRate: 0.01,
    // Docs: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/sampling/#sampling-error-events
    sampleRate: getSentrySampleRate(),
    // Docs: https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-nameignore-errors-
    ignoreErrors: [
      /get shopify checkout data failed/,
      '[Network] Failed to fetch',
      'Non-Error promise rejection captured with value: load image failed',
      'isOperating',
      'isLocked',
    ],
  })
  void shopifyClient.getCheckoutIdAndSendToSentry()
}

app.mount('#app')
