import { t } from 'i18next'
import { useQuery } from 'villus'

import {
  ProductsByDeviceHandleDocument,
  type ProductsByDeviceHandleQuery,
  ProductsByProductSeriesListDocument,
  type ProductsByProductSeriesListQuery,
} from '@/graphql/generated_villus'
import { dittoMappingBackendLocale } from '@/graphql/utils'
import { getCurrentLocale } from '@/locale'

import { type Product, type ProductList, type ProductVariant } from './types'

// 針對 product series 撈 product list
export async function getShopifyProducts(
  productSeriesList: readonly string[],
): Promise<ProductList | null> {
  const variables = {
    productSeriesList: [...productSeriesList],
    locale: dittoMappingBackendLocale[getCurrentLocale()],
  }
  const { data, error } = await useQuery({
    query: ProductsByProductSeriesListDocument,
    variables,
  })
  if (data.value === null || error.value !== null) {
    return null
  }
  return recomposeProduct(data.value.productsByProductSeriesList.products)
}

// 針對裝置撈 product list
export async function getShopifyProductsForDevice(
  deviceHandle: string,
): Promise<ProductList | null> {
  const variables = {
    deviceHandle,
    locale: dittoMappingBackendLocale[getCurrentLocale()],
  }
  const { data, error } = await useQuery({
    query: ProductsByDeviceHandleDocument,
    variables,
  })
  if (data.value === null || error.value !== null) {
    return null
  }
  return recomposeProduct(data.value.productsByDeviceHandle.products)
}

function recomposeProduct(
  inputProducts:
    | ProductsByDeviceHandleQuery['productsByDeviceHandle']['products']
    | ProductsByProductSeriesListQuery['productsByProductSeriesList']['products'],
): ProductList {
  const products = new Map<string, Product>()
  for (const item of inputProducts) {
    const overwriteDesignSize: Product['overwriteDesignSize'] = item.overwriteDesignSize ?? null
    const productItem = {
      title: t(`series.${item.title}`),
      thumbnailImage: item.thumbnailImage,
      bindingDesignGroup: item.bindingDesignGroup,
      productVariants: new Map<string, ProductVariant>(),
      overwriteDesignSize,
    }
    for (const variantItem of item.productVariants) {
      // TODO(Benson): hard code 未來這些資料等後端有 i18n 之後看要怎麼搞
      let groupTitle: null | string = null
      let groupTitleNote: null | string = null
      if (variantItem.isBackplateOnly !== undefined && variantItem.isBackplateOnly !== null) {
        // isBackplateOnly 不是 null 表示是 mod 系列
        groupTitle = t(
          `version.${variantItem.isBackplateOnly ? 'backplate_only' : 'case_backplate'}`,
        )
        groupTitleNote = variantItem.hasModNxBundleNote ? t('version.case_backplate_note') : null
      }

      const productVariant = {
        versionTitle: t(`version.${variantItem.version}`),
        groupTitle,
        groupTitleNote,
        colorForVariants: new Map(),
        preDesignLayers: variantItem.preDesignLayers,
        postDesignLayers: variantItem.postDesignLayers,
        previewMask: variantItem.previewMask,
        printInfo: variantItem.printInfo,
        designDeconstructionHandle: variantItem.designDeconstructionHandle,
        viewableRect: variantItem.viewableRect,
      }
      for (const colorItem of variantItem.colorForVariants) {
        productVariant.colorForVariants.set(colorItem.colorCode, {
          price: colorItem.price,
          compareAtPrice: colorItem.compareAtPrice,
          variants: colorItem.variants,
          images: new Map(colorItem.images.map((imageItem) => [imageItem.type, imageItem.url])),
        })
      }
      productItem.productVariants.set(variantItem.productHandle, productVariant)
    }
    products.set(item.productSeries, productItem)
  }

  return products
}
