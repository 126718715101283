import { setTag } from '@sentry/vue'
import shopifyBuy, { type Checkout } from 'shopify-buy'

import { type Locale, type ShopifyConfig, getCurrentLocale, shopifyTokenList } from '@/locale'
import { isObject } from '@/type.util'

import CookieService from './CookieService'
import { sentry } from './sentry'

// TODO: 先做 properties 相容，預計四月底可以拆掉舊的 properties
export interface Properties {
  /**
   * 資料庫儲存是用 number type
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _bundleId?: number
  /**
   * 圖片預覽網址
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _ecommerce?: object
  /**
   * TODO: GA 資料 待捕
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _imageUrl: string
  /**
   * 產品名稱（選填），如果沒填就會用 shopify 原本的產品名稱
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _name?: string
  /**
   * 服務名稱
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _service: 'sticker'
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _specific: 'ditto'
  /**
   * 結帳復原網址
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _url?: string
  /**
   * 一組的 (e.g. Mod 整組購買就需要加上這個)
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CCID?: number
}

export interface ShopifyAddLineItem {
  properties: Properties
  quantity: number
  variantId: string // gid://shopify/ProductVariant/42773575237817
}

function propertiesConvertToCustomAttributes(
  properties: Properties,
): Array<Record<'key' | 'value', string>> {
  return Object.entries(properties)
    .filter(
      (item): item is [keyof Properties, Exclude<Properties[keyof Properties], undefined>] =>
        item[1] !== undefined,
    )
    .map(([key, value]) => {
      if (typeof value === 'object') {
        value = JSON.stringify(value)
      } else if (typeof value === 'number') {
        value = value.toString()
      }
      return {
        key,
        value,
      }
    })
}

interface ShopifyCheckoutResult extends Checkout {
  userErrors: Array<{
    field: Array<{
      type: Record<'kind' | 'name', string>
      value: string
    }>
    message: string
    type: {
      fieldBaseTypes: Record<'field' | 'message', string>
      implementsNode: boolean
      kind: string
      name: string
    }
  }>
}

// shopify buy 在做 checkout result 的時候都沒寫到 `userErrors` 的型別，只能自己判斷了
function isShopifyCheckoutResult(result: Checkout): result is ShopifyCheckoutResult {
  return Boolean(
    isObject(result) &&
      'userErrors' in result &&
      typeof result.userErrors === 'object' &&
      Array.isArray(result.userErrors),
  )
}

class ShopifyService {
  private _checkoutId: null | string = null
  private readonly _client: shopifyBuy
  private readonly _cookieClient: CookieService
  private readonly _locale: Locale
  private readonly _shopifyConfig: ShopifyConfig // gid://shopify/Checkout/XXX?key=YYY

  public constructor(locale: Locale) {
    this._locale = locale
    this._shopifyConfig = shopifyTokenList[this._locale]
    this._cookieClient = new CookieService(locale)
    this._client = shopifyBuy.buildClient({
      domain: this._shopifyConfig.shopDomain.replace('https://', ''),
      storefrontAccessToken: this._shopifyConfig.storefrontAccessToken,
      apiVersion: '2023-04',
    })
  }

  public async addDiscount(discountCode: string): Promise<boolean> {
    await this.checkAndCreateCheckoutId()
    if (this._checkoutId === null) {
      return false
    }
    try {
      const result = await this._client.checkout.addDiscount(this._checkoutId, discountCode)
      if (!isShopifyCheckoutResult(result)) {
        sentry.error(`shopify add discount '${discountCode}' no return userErrors`)
        return false
      }
      if (result.userErrors.length > 0) {
        const errorMessages: string[] = []
        for (const error of result.userErrors) {
          errorMessages.push(error.message)
        }
        sentry.error(`shopify add discount '${discountCode}' failed`, {
          errorMessages,
        })
        return false
      }
      return true
    } catch (error) {
      sentry.error(`shopify add discount '${discountCode}' failed`, {
        error,
      })
      return false
    }
  }

  public async addLineItem(
    items: ShopifyAddLineItem[],
  ): Promise<{ lineItems: Checkout['lineItems'] | null; success: boolean }> {
    const checkoutId = await this.checkAndCreateCheckoutId()

    const shopifyLineItems: shopifyBuy.CheckoutLineItemInput[] = items.map((item) => ({
      variantId: item.variantId,
      quantity: item.quantity,
      customAttributes: propertiesConvertToCustomAttributes(item.properties),
    }))
    const variantIds = items.map((item) => item.variantId)

    try {
      const result = await this._client.checkout.addLineItems(checkoutId, shopifyLineItems)
      if (!isShopifyCheckoutResult(result)) {
        sentry.error(`shopify add lineItem ${variantIds.join(', ')} no return userErrors`)
        return { success: false, lineItems: result.lineItems }
      }
      if (result.userErrors.length > 0) {
        const errorMessages: string[] = []
        for (const error of result.userErrors) {
          errorMessages.push(error.message)
        }
        sentry.error(`shopify add lineItem ${variantIds.join(', ')} failed`, {
          errorMessages,
        })
        return { success: false, lineItems: result.lineItems }
      }
      return { success: true, lineItems: result.lineItems }
    } catch (error) {
      sentry.error('shopify add line item failed', {
        variantIds,
        error,
      })
      return { success: false, lineItems: null }
    }
  }

  public async checkAndCreateCheckoutId(): Promise<string> {
    let { _checkoutId: checkoutId } = this
    if (checkoutId !== null && checkoutId !== '') {
      return checkoutId
    }

    // 因為需要檢查現在的 checkoutId 是否已經結帳了，所以需要把 cart fetch 回來
    const { _cookieClient: cookieClient, _client: client } = this
    let checkout: null | shopifyBuy.Checkout = null
    checkoutId = cookieClient.getCheckoutId()
    if (checkoutId !== null && checkoutId !== '') {
      try {
        checkout = await client.checkout.fetch(checkoutId)
      } catch (error) {
        sentry.error('get shopify checkout data failed', {
          checkoutId,
          error,
        })
      }
    }
    if (checkout === null || typeof checkout.completedAt === 'string') {
      checkout = await client.checkout.create()
    }

    // 因為有可能更新了 checkout 資料，導致 checkout id 換新的，所以這邊需要檢查跟 set 新的 checkout id
    if (checkoutId !== checkout.id.toString()) {
      checkoutId = checkout.id.toString()
      cookieClient.setCheckoutId(checkoutId)
    }

    this._checkoutId = checkoutId
    return checkoutId
  }

  public async getCartItemCountTotal(): Promise<number> {
    await this.checkAndCreateCheckoutId()
    if (this._checkoutId === null) {
      return 0
    }
    try {
      // checkout 資料保留目前看起來沒意義，因為需要重新 fetch 才會抓到目前比較正確的資料
      const cart = await this._client.checkout.fetch(this._checkoutId)
      let totalItems = 0
      for (const lineItem of cart.lineItems) {
        totalItems += lineItem.quantity
      }
      return totalItems
    } catch {
      return 0
    }
  }

  public async getCheckoutIdAndSendToSentry(): Promise<void> {
    await this.checkAndCreateCheckoutId()
    if (this._checkoutId === null) {
      return
    }
    const checkoutId = this._checkoutId.replace(/^gid:\/\/shopify\/Checkout\/(.*)\?key=.*$/, '$1')
    if (checkoutId !== '') {
      setTag('checkoutToken', checkoutId)
    }
  }

  public async removeLineItems(lineItemIds: string[]): Promise<void> {
    const checkoutId = await this.checkAndCreateCheckoutId()
    await this._client.checkout.removeLineItems(checkoutId, lineItemIds)
  }
}

const shopifyClient = new ShopifyService(getCurrentLocale())
void shopifyClient.getCheckoutIdAndSendToSentry()
export default shopifyClient
