import { t } from 'i18next'
import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'

import { type ProductSubSelectKey } from '@/components/productNavigator/mobile/ProductPanel.type'
import {
  defaultProductNavigation,
  getProductNavigation,
  productNavigationMappingSubSelects,
} from '@/data'
import { productSeriesThumbnailImages } from '@/data/productHandle.config'
import { type ProductNavigation } from '@/data/types'
import { getCurrentLocale } from '@/locale'
import CookieService from '@/utils/CookieService'
import {
  getQueryStringDeviceColor,
  getQueryStringDeviceHandle,
  getQueryStringProductColor,
  getQueryStringProductHandle,
  getQueryStringProductNavigation,
} from '@/utils/queryString'

import { useDeviceStore } from './deviceStore'
import { useProductStore } from './productStore'
import { useStateStore } from './stateStore'

const cookieClient = new CookieService(getCurrentLocale())

export const useProductNavigationStore = defineStore('productNavigation', () => {
  const stateStore = useStateStore()
  const deviceStore = useDeviceStore()
  const productStore = useProductStore()
  const isProductNavigationLoading = ref(false)

  // 需要等資料都重新就定位了以後才把原本 cookie or query string 的資料放回去，不然會被 watch 覆蓋掉
  function cacheAndRecoveryState(
    state: Record<'deviceColor' | 'deviceHandle' | 'productColor' | 'productHandle', null | string>,
  ): () => Promise<void> {
    const { deviceHandle, deviceColor, productHandle, productColor } = state
    return async function (): Promise<void> {
      await deviceStore.updateDevice(deviceHandle)
      if (deviceColor !== null) {
        deviceStore.updateDeviceColor(deviceColor)
      }
      if (productHandle !== null) {
        productStore.updateProduct(productHandle)
      }
      if (productColor !== null) {
        productStore.updateProductColor(productColor)
      }
    }
  }

  // 因為 product navigation 從後端來，所以這邊只好改用 ref 在 initial 完了以後推進去放
  const selectableProductNavigation = ref<string[]>([])
  const selectedProductNavigation = computed(() => stateStore.selectedProductNavigation)
  async function updateProductNavigation(
    productNavigation: string,
    recoveryState?: () => Promise<void>,
  ): Promise<void> {
    // product navigation 更新中的時候不允許其他 product navigation 的更新，避免因為一個有 cache，一個需要 await，讓 product 跟 product navigation 資料不同步
    if (isProductNavigationLoading.value) {
      return
    }
    isProductNavigationLoading.value = true
    if (selectableProductNavigation.value.includes(productNavigation)) {
      const currentProductNavigation = productNavigationMap.value.get(productNavigation)
      if (currentProductNavigation === undefined) {
        isProductNavigationLoading.value = false
        return
      }
      const { deviceType, defaultDevice } = currentProductNavigation

      const recoveryCookieState =
        recoveryState ??
        cacheAndRecoveryState({
          deviceHandle: cookieClient.getDeviceHandle(productNavigation) ?? defaultDevice,
          deviceColor: cookieClient.getDeviceColor(productNavigation),
          productHandle: cookieClient.getProductHandle(productNavigation),
          productColor: cookieClient.getProductColor(productNavigation),
        })
      stateStore.updateProductNavigation(productNavigation)
      await deviceStore.updateDeviceData(deviceType)
      await productStore.updateProductsForProductNavigation(
        productNavigation,
        stateStore.selectedDeviceHandle,
        productNavigationMap,
      )
      await recoveryCookieState()
    }
    isProductNavigationLoading.value = false
  }

  const productPanelMenu = computed(() => {
    const menu = new Map<
      string,
      {
        image: string
        subSelect: Map<ProductSubSelectKey, string>
        title: string
      }
    >()
    for (const [productNavigation, { type, image }] of productNavigationMap.value.entries()) {
      const title = t(`category.${productNavigation}`)
      const subSelectList = productNavigationMappingSubSelects[type]
      if (subSelectList === undefined) {
        continue
      }
      const subSelect = new Map<ProductSubSelectKey, string>(
        subSelectList.map((key) => [key, t(`panel.navigation.${productNavigation}.${key}`)]),
      )
      menu.set(productNavigation, {
        title,
        image,
        subSelect,
      })
    }

    return menu
  })
  const currentAllProductSeries = computed(
    (): Array<{
      productSeriesHandle: string
      productSeriesThumbnailImage: null | string
      productSeriesTitle: string
    }> => {
      const currentHaveProductSeries = productNavigationMap.value.get(
        selectedProductNavigation.value,
      )?.productSeriesList
      if (currentHaveProductSeries === undefined) {
        return []
      }
      return currentHaveProductSeries.map((productSeriesHandle) => {
        return {
          productSeriesHandle,
          productSeriesTitle: t(`series.${productSeriesHandle}`),
          productSeriesThumbnailImage: productSeriesThumbnailImages[productSeriesHandle] ?? null,
        }
      })
    },
  )
  const currentProductDesignSize = computed(
    () =>
      productStore.overwriteProductDesignSize ??
      productNavigationMap.value.get(selectedProductNavigation.value)?.designSize ?? {
        offsetX: 0,
        offsetY: 0,
        width: 0,
        height: 0,
      },
  )
  const currentProductType = computed(
    () => productNavigationMap.value.get(selectedProductNavigation.value)?.type ?? null,
  )
  const currentProductNote = computed(
    (): Record<'briefDescription' | 'dialogContent' | 'dialogTitle', string> | null => {
      const note = productNavigationMap.value.get(selectedProductNavigation.value)?.note ?? null
      if (note === null) {
        return null
      }
      return {
        briefDescription: t(note.briefDescription),
        dialogTitle: t(note.dialogTitle),
        dialogContent: t(note.dialogContent),
      }
    },
  )

  const currentDesignGroupType = computed(
    () => productNavigationMap.value.get(selectedProductNavigation.value)?.designGroupType ?? null,
  )

  const productNavigationMap = ref<ProductNavigation>(new Map([]))
  async function updateProductNavigationMap(): Promise<void> {
    const productNavigationData = await getProductNavigation()
    if (productNavigationData !== null) {
      productNavigationMap.value = productNavigationData
    }
  }

  async function initProcess(): Promise<void> {
    await updateProductNavigationMap()
    selectableProductNavigation.value = [...productNavigationMap.value.keys()]

    let productNavigation: string = defaultProductNavigation
    const queryStringProductNavigation = getQueryStringProductNavigation()
    const cookieProductNavigation = cookieClient.getProductNavigation()
    if (
      queryStringProductNavigation !== null &&
      selectableProductNavigation.value.includes(queryStringProductNavigation)
    ) {
      productNavigation = queryStringProductNavigation
    } else if (
      cookieProductNavigation !== null &&
      selectableProductNavigation.value.includes(cookieProductNavigation)
    ) {
      productNavigation = cookieProductNavigation
    }

    const defaultDevice = productNavigationMap.value.get(productNavigation)?.defaultDevice ?? null
    const recoveryQueryStringState = cacheAndRecoveryState({
      deviceHandle:
        getQueryStringDeviceHandle() ??
        cookieClient.getDeviceHandle(productNavigation) ??
        defaultDevice,
      deviceColor: getQueryStringDeviceColor() ?? cookieClient.getDeviceColor(productNavigation),
      productHandle:
        getQueryStringProductHandle() ?? cookieClient.getProductHandle(productNavigation),
      productColor: getQueryStringProductColor() ?? cookieClient.getProductColor(productNavigation),
    })
    await updateProductNavigation(productNavigation, recoveryQueryStringState)
    await productStore.initProductPendingSkus()
    initialized.value = true
  }

  let initSingleton: Promise<void> | null = null
  const initialized = ref(false)
  async function init(): Promise<void> {
    if (initSingleton == null) {
      initSingleton = initProcess()
    }
    await initSingleton
  }
  void init()

  return {
    init,
    initialized: readonly(initialized),
    selectedProductNavigation: readonly(selectedProductNavigation),
    selectableProductNavigation: readonly(selectableProductNavigation),
    productNavigationMap: readonly(productNavigationMap),
    updateProductNavigation,
    productPanelMenu,
    currentAllProductSeries,
    currentProductDesignSize,
    currentProductType,
    currentProductNote,
    isProductNavigationLoading: readonly(isProductNavigationLoading),
    currentDesignGroupType,
  }
})
